import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102')
];

export const server_loads = [0,6,2];

export const dictionary = {
		"/(orion)": [~38,[6]],
		"/404": [98],
		"/(auth)/admin": [18,[2]],
		"/(backoffice)/backoffice": [28,[3]],
		"/(backoffice)/backoffice/bandeiras": [29,[3]],
		"/(backoffice)/backoffice/bandeiras/[id]": [30,[3]],
		"/(backoffice)/backoffice/disparadores": [31,[3]],
		"/(backoffice)/backoffice/marretagens": [32,[3]],
		"/(backoffice)/backoffice/vendas": [33,[3]],
		"/(orion)/banners": [39,[6,7]],
		"/(orion)/banners/cadastrar": [41,[6,7]],
		"/(orion)/banners/[id]": [40,[6,7]],
		"/(bussola)/bussola": [34,[4]],
		"/(bussola)/bussola/(steps)/analise": [35,[4,5]],
		"/(bussola)/bussola/(steps)/analise/[lojaId]": [36,[4,5]],
		"/(bussola)/bussola/(steps)/analise/[lojaId]/[categoria]": [37,[4,5]],
		"/(auth)/cadastrar": [19,[2]],
		"/(orion)/cadastro-promocao": [~42,[6,8]],
		"/(orion)/categorias": [43,[6,9]],
		"/(orion)/categorias/cadastrar": [45,[6,9]],
		"/(orion)/categorias/[id]": [44,[6,9]],
		"/(orion)/cursos": [46,[6,10]],
		"/(orion)/cursos/ao-vivo": [49,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/(listar)": [52,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/cadastrar": [54,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/quizzes/(listar)": [55,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/quizzes/cadastrar/[tipo]": [~57,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/quizzes/[id]": [56,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/[id]": [53,[6,10,11]],
		"/(orion)/cursos/ao-vivo/[id]": [50,[6,10,11]],
		"/(orion)/cursos/ao-vivo/[id]/ingressar": [~51,[6,10,11]],
		"/(orion)/cursos/gerenciar/(listar)": [58,[6,10]],
		"/(orion)/cursos/gerenciar/cadastrar": [60,[6,10]],
		"/(orion)/cursos/gerenciar/[id]": [59,[6,10]],
		"/(orion)/cursos/pesquisar": [61,[6,10]],
		"/(orion)/cursos/solucoes": [~62,[6,10]],
		"/(orion)/cursos/solucoes/[id]": [~63,[6,10]],
		"/(orion)/cursos/solucoes/[id]/[treinamento]": [64,[6,10]],
		"/(orion)/cursos/[id]": [47,[6,10]],
		"/(orion)/cursos/[id]/[aula]": [48,[6,10]],
		"/erro": [~100],
		"/(orion)/fidelizamais": [65,[6,12]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]": [~66,[6,12,13]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]/ativacao": [67,[6,12,13]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]/configuracoes": [68,[6,12,13]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]/revisao": [69,[6,12,13]],
		"/(orion)/fidelizamais/gerenciar": [70,[6,12]],
		"/(orion)/fidelizamais/minhas-lojas": [71,[6,12]],
		"/icons": [101],
		"/(auth)/login": [20,[2]],
		"/(auth)/logout": [~21,[2]],
		"/(orion)/mais": [72,[6]],
		"/(orion)/mais/noticias": [73,[6]],
		"/(orion)/mais/noticias/lancamentos": [75,[6]],
		"/(orion)/mais/noticias/lancamentos/[id]": [76,[6]],
		"/(orion)/mais/noticias/[id]": [74,[6]],
		"/(orion)/mais/perfil": [77,[6]],
		"/(orion)/medicamentos": [78,[6,14]],
		"/(orion)/medicamentos/pbm": [79,[6,14]],
		"/(orion)/medicamentos/pbm/gerenciar": [80,[6,14]],
		"/(orion)/medicamentos/pbm/orientacoes": [81,[6,14,15]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar": [83,[6,14,15,16]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar/cadastrar": [85,[6,14,15,16]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar/[id]": [84,[6,14,15,16]],
		"/(orion)/medicamentos/pbm/orientacoes/[id]": [82,[6,14,15]],
		"/(orion)/noticias": [86,[6]],
		"/(orion)/noticias/gerenciar": [88,[6,17]],
		"/(orion)/noticias/gerenciar/cadastrar": [90,[6,17]],
		"/(orion)/noticias/gerenciar/[id]": [89,[6,17]],
		"/(orion)/noticias/lancamentos": [91,[6]],
		"/(orion)/noticias/lancamentos/[id]": [92,[6]],
		"/(orion)/noticias/[id]": [87,[6]],
		"/(orion)/ofertas": [93,[6]],
		"/(orion)/perfil": [94,[6]],
		"/privacidade": [102],
		"/(orion)/promocoes": [~95,[6]],
		"/(auth)/recuperar-senha": [22,[2]],
		"/(auth)/redefinir-senha": [23,[2]],
		"/(auth)/sso": [~24,[2]],
		"/(auth)/sso/[token]": [25,[2]],
		"/(orion)/trade": [96,[6]],
		"/(orion)/troca-email": [97,[6]],
		"/(auth)/v2sso": [~26,[2]],
		"/(auth)/v2sso/[token]": [~27,[2]],
		"/[...naoencontrada]": [~99]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';